import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import classnames from "classnames"

import { handelize } from "../utils/utils"
import { filterValidNameFields } from "../utils/data"

const LINK_TEXT = "Artists"
const VIEW_ALL_LINK_TEXT = "View All"

const ArtistsNav = ({ sharedLinkClasses }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const handleClick = () => toggleExpansion(!isExpanded)

  useEffect(() => {
    if (typeof window !== "undefined" && !!window.document) {
      const html = document.querySelector("html")
      isExpanded
        ? (html.style.overflow = "hidden")
        : (html.style.overflow = "visible")
    }
  }, [isExpanded])

  const { airtableArtists } = useStaticQuery(graphql`
    {
      airtableArtists: allAirtable(
        filter: {
          table: { eq: "Artists" }
          data: { TNLG_Artist_: { eq: true } }
        }
        sort: { fields: data___Last_Name }
      ) {
        edges {
          node {
            data {
              Name
              Featured_Art {
                data {
                  Name
                  Images {
                    localFiles {
                      childImageSharp {
                        fluid(maxWidth: 500) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const artists = filterValidNameFields(airtableArtists)

  return (
    <li>
      <button
        className={classnames(sharedLinkClasses, "flex items-center ml-auto", {
          italic: isExpanded,
        })}
        onClick={handleClick}
      >
        {LINK_TEXT}
        <div
          className={classnames(
            "ml-3 border-b border-l w-2 h-2 border-current transform",
            {
              "-rotate-45 mb-2": !isExpanded,
              "rotate-135": isExpanded,
            }
          )}
        />
      </button>
      <div
        className={`${
          isExpanded ? "md:absolute" : "hidden"
        } md:bg-light md:text-green right-0 w-full text-right md:px-4 md:z-20 md:pt-4 md:max-h-screen md:overflow-scroll md:mt-dnav`}
      >
        <ul className="max-w-6xl mx-auto md:pb-48">
          <li>
            <Link
              to={`/artists`}
              className="block pb-4 md:pb-6"
              onClick={() => toggleExpansion(false)}
            >
              {VIEW_ALL_LINK_TEXT}
            </Link>
          </li>
          {artists
            .filter(
              e =>
                e.node.data.Featured_Art &&
                e.node.data.Featured_Art[0].data.Images
            )
            .map(({ node }, i) => {
              const { Name } = node.data
              return (
                <li key={i}>
                  <Link
                    to={`/${handelize(Name)}`}
                    className="no-underline md:text-2xl pb-3 block opacity-50 hover:opacity-100"
                  >
                    {Name}
                  </Link>
                </li>
              )
            })}
        </ul>
      </div>
    </li>
  )
}

export default ArtistsNav
