import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import AnnounceBar from "./announce-bar"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <>
      <div className="flex flex-col min-h-screen text-green">
        <AnnounceBar />
        <Header />
        <main className="flex-grow">{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
