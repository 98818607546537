import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Newsletter from "./newsletter"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      prismicSite {
        data {
          footer_text {
            text
          }
          phone_number
          email
          instagram_link {
            url
            target
          }
          facebook_link {
            url
            target
          }
        }
      }
    }
  `)

  const {
    footer_text,
    phone_number,
    email,
    instagram_link,
    facebook_link,
  } = data.prismicSite.data
  return (
    <>
      <Newsletter />
      <footer className="text-center">
        <div className="px-4 py-6 md:py-10 text-lg md:text-2xl">
          <p>{footer_text.text}</p>
          <p>
            <a href={`tel:${phone_number}`}>{phone_number}</a>
            {" | "}
            <a href={`mailto:${email}`}>{email}</a>
          </p>
          <div className="flex justify-center text-xl md:text-2xl">
            <a
              href={facebook_link.url}
              className="px-2"
              target={facebook_link.target}
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href={instagram_link.url}
              className="px-2"
              target={instagram_link.target}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>

        <div className="bg-light py-2 text-xs">
          <p className="mb-0">
            Copyright © {new Date().getFullYear()} The New Leaf Gallery, LLC —
            All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
