import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import classnames from "classnames"

import ArtistsNav from "./artists-nav"
import { HorizLogo } from "./logo"

const navLinks = [
  {
    text: "Artists",
    link: "/artists/",
  },
  {
    text: "Exhibits",
    link: "/exhibits/",
  },
  {
    text: "Events",
    link: "/events/",
  },
  {
    text: "Library",
    link: "/library/",
  },
  {
    text: "Blog",
    link: "/blog/",
  },
  {
    text: "Visit & Shop",
    link: "/visit-shop/",
  },
]

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)
  const handleToggleExpand = () => toggleExpansion(!isExpanded)

  useEffect(() => {
    if (typeof window !== "undefined" && !!window.document) {
      const html = document.querySelector("html")
      isExpanded
        ? (html.style.overflow = "hidden")
        : (html.style.overflow = "visible")
    }
  }, [isExpanded])

  const sharedLinkClasses =
    "no-underline border-b border-transparent hover:border-light text-lg block pb-4 md:pb-0 text-right transition duration-150 ease-in-out"

  return (
    <div className="z-10 sticky top-0">
      <nav className="pl-2 pr-4 flex items-center justify-between relative w-full fill-green bg-light border-b border-green transition-all duration-150">
        <Link
          to={`/`}
          className="flex items-center no-underline flex-shrink-0 mr-10"
        >
          <HorizLogo className="fill-current h-12 md:h-16" />
        </Link>
        <button className="md:hidden" onClick={handleToggleExpand}>
          <span
            className={classnames(`c-close-btn w-6 h-6 block relative`, {
              "c-close-btn--expanded": isExpanded,
            })}
          />
        </button>
        <div
          className={classnames(
            "mt-mnav md:mt-0 z-20 absolute md:static top-0 left-0 md:block md:flex-grow w-full md:max-w-xl max-h-screen overflow-scroll md:overflow-visible transition duration-200 ease-in-out pb-40 md:pb-0",
            {
              block: isExpanded,
              hidden: !isExpanded,
            }
          )}
        >
          <div className="p-4 fill-green bg-light border-b border-green md:border-none">
            <ul className="w-full md:flex md:justify-between">
              {navLinks.map(({ text, link }) => {
                if (text === "Artists") {
                  return (
                    <ArtistsNav
                      key={text}
                      sharedLinkClasses={sharedLinkClasses}
                    />
                  )
                } else if (!!link) {
                  return (
                    <li key={text}>
                      <Link to={link} className={sharedLinkClasses}>
                        {text}
                      </Link>
                    </li>
                  )
                }
                return <></>
              })}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
