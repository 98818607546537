import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import RichText from "./richtext"

const AnnounceBar = () => {
  const data = useStaticQuery(graphql`
    {
      prismicSite {
        data {
          announcement_bar_text {
            html
          }
        }
      }
      airtableSiteUpdated: allAirtable(
        filter: { table: { eq: "Site Updated" } }
      ) {
        edges {
          node {
            data {
              Updated(formatString: "MMMM D, YYYY")
            }
          }
        }
      }
    }
  `)

  return (
    <div className="px-3 py-2 leading-tight bg-green text-white text-sm md:flex md:justify-between text-center md:text-left">
      <RichText
        html={data.prismicSite.data.announcement_bar_text.html}
        type="Single Line"
      />
      <div className="text-sm">
        Last Updated: {data.airtableSiteUpdated.edges[0].node.data.Updated}
      </div>
    </div>
  )
}

export default AnnounceBar
